/*** bootstrap-tagsinput v0.8.0 ***/

.bootstrap-tagsinput {
  display: flex;
  flex-wrap: wrap;
  background: $white;
  font-family: sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #556270;
  padding: 5px 5px 0;

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: auto;
    max-width: inherit;
    display: block;
    overflow: hidden;
  }

  &.form-control input {
    &::-moz-placeholder {
      color: #777;
      opacity: 1;
    }

    &:-ms-input-placeholder,
    &::-webkit-input-placeholder {
      color: #777;
    }
  }

  input:focus {
    border: none;
    box-shadow: none;
  }

  .tag {
    position: relative;
    background: $primary !important;
    display: block;
    max-width: 95%;
    word-wrap: break-word;
    white-space: break-spaces;
    color: $white;
    padding: 3px 8px !important;
    border-radius: 3px;
    margin: 0 5px 5px 0;

    [data-role="remove"] {
      margin-inline-start: 20px;
      cursor: pointer;

      &:after {
        content: "×";
        padding: 3px 6px;
        background-color: $white-2;
        border-radius: 50%;
        font-size: 12px;
        line-height: 1;
        display: inline-block;
        text-align: center;
        margin-block-start: 2px;
        padding-block-start: 4px;
      }

      &:hover {
        &:after {
          background-color: $black-2;
        }

        &:active {
          box-shadow: inset 0 3px 5px $black-1;
        }
      }
    }
  }
}
/*** bootstrap-tagsinput v0.8.0 ***/