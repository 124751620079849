.accordionjs {
	position: relative;
	margin: 0;
	padding: 0;
	list-style: none;
	box-shadow: 0px 0px 51px 0px rgba(122, 128, 144, 0.08), 0px 6px 18px 0px rgba(122, 128, 144, 0.05);

	.acc_section {
		border: 1px solid #eff2f7;
		position: relative;
		z-index: 10;
		margin-block-start: -1px;
		overflow: hidden;

		.acc_head {
			position: relative;
			background: $white;
			padding: 15px;
			display: block;
			cursor: pointer;

			h3 {
				line-height: 1;
				margin: 5px;
				font-size: 15px;
			}
		}

		.acc_content {
			padding: 15px;
			background: $white;
		}

		&:first-of-type {
			border-start-start-radius: 3px;
			border-start-end-radius: 3px;

			.acc_head {
				border-start-start-radius: 3px;
				border-start-end-radius: 3px;
			}
		}

		&:last-of-type {
			border-end-start-radius: 3px;
			border-end-end-radius: 3px;

			.acc_content {
				border-end-start-radius: 3px;
				border-end-end-radius: 3px;
			}
		}

		&.acc_active {
			> {
				.acc_content {
					display: block;
				}

				.acc_head {
					border-block-end: 1px solid #eff4fc;
					color: #6a7882;
				}
			}

			.acc_head h3:before {
				content: "\e994";
				position: absolute;
				font-family: "feather" !important;
				inset-inline-end: 15px;
				top: 17px;
				font-size: 20px;
				transition: all 0.5s;
				transform: scale(1);
			}
		}

		.acc_head h3:before {
			content: "\e9af";
			position: absolute;
			font-family: "feather" !important;
			inset-inline-end: 15px;
			top: 17px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
	}
}
