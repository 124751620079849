/*!
 * Datepicker for Bootstrap v1.8.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */

.datepicker {
  border-radius: 5px;
  direction: ltr;
  background-color: $white;
  border: 1px solid $border;
  overflow: hidden;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;

  &.dropdown-menu {
    inset-inline-start: auto;
  }

  table tr td span {
    float: $float-right;
  }
}

.datepicker-dropdown {
  top: 0;
  inset-inline-start: 0;

  &:before {
    content: "";
    display: inline-block;
    border-inline-start: 7px solid transparent;
    border-inline-end: 7px solid transparent;
    border-block-end: 7px solid #f0f3fa;
    border-block-start: 0;
    border-block-end-color: $black-2;
    position: absolute;
  }

  &:after {
    content: "";
    display: inline-block;
    border-inline-start: 6px solid transparent;
    border-inline-end: 6px solid transparent;
    border-block-end: 6px solid #f0f3fa;
    border-block-start: 0;
    position: absolute;
  }

  &.datepicker-orient-left {
    &:before {
      inset-inline-start: 6px;
    }

    &:after {
      inset-inline-start: 7px;
    }
  }

  &.datepicker-orient-right {
    &:before {
      inset-inline-end: 6px;
    }

    &:after {
      inset-inline-end: 7px;
    }
  }

  &.datepicker-orient-bottom {
    &:before {
      top: -7px;
    }

    &:after {
      top: -6px;
    }
  }

  &.datepicker-orient-top {
    &:before {
      bottom: -7px;
      border-block-end: 0;
      border-block-start: 7px solid #f0f3fa;
    }

    &:after {
      bottom: -6px;
      border-block-end: 0;
      border-block-start: 6px solid #f0f3fa;
    }
  }
}

.datepicker {
  table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-collapse: inherit;
  }

  td {
    text-align: center;
    padding: 6px 10px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border: none;
    font-size: 12px;
    color: #4a4a69;
  }

  .datepicker-switch {
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover {
      background: transparent !important;
    }
  }

  .prev:hover,
  .next:hover,
  tfoot tr th:hover {
    background: transparent !important;
  }

  th {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 6px 9px;
    color: $gray-800;
  }
}

.datepicker-days tbody {
  background: $white;
}

.table-striped .datepicker table tr {
  td,
  th {
    background-color: transparent;
  }
}

.datepicker {
  table tr td {
    &.day {
      &:hover,
      &.focused {
        background: $white-1;
        cursor: pointer;
      }
    }

    &.old,
    &.new {
      opacity: 0.5;
    }

    &.disabled {
      background: none;
      cursor: default;

      &:hover {
        background: none;
        cursor: default;
      }
    }

    &.highlighted {
      background: #d9edf7;
      border-radius: 0;
    }

    &.today {

      &.disabled {
        background-color: #fde19a;
        background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
        background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
        border-color: #fdf59a #fdf59a #fbed50;
        border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: #7e7da0;

        &:hover {
          background-color: #fde19a;
          background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
          background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
          border-color: #fdf59a #fdf59a #fbed50;
          border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: #7e7da0;
        }
      }

      &:hover,
      &.disabled:hover,
      &:active,
      &:hover:active {
        background-color: #fdf59a;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #fdf59a;
        }
      }

      &.active,
      &:hover.active {
        background-color: #fdf59a;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #fdf59a;
        }

        background-color: #fdf59a;
      }

      &:hover.disabled {
        background-color: #fdf59a;
      }

      &.disabled {
        &.disabled,
        &:hover.disabled {
          background-color: #fdf59a;
        }
      }

      &[disabled],
      &:hover[disabled] {
        background-color: #fdf59a;
      }

      &.disabled {
        &[disabled],
        &:hover[disabled] {
          background-color: #fdf59a;
        }
      }

      &:active,
      &:hover:active {
        background-color: #fbf069 \9;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #fbf069 \9;
        }
      }

      &.active,
      &:hover.active {
        background-color: #fbf069 \9;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #fbf069 \9;
        }
      }

      &:hover:hover {
        color: #000;
      }

      &.active:hover {
        color: $white;
      }
    }

    &.range {
      background: #f0f2f7;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;

      &:hover {
        background: #f0f2f7;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
      }

      &.disabled {
        background: #f0f2f7;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        &:hover {
          background: #f0f2f7;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }
      }

      &.today {
        background-color: $primary;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;

        &:hover {
          background-color: $primary;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
        }

        &.disabled {
          background-color: #f3d17a;
          background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
          background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
          border-color: #f3e97a #f3e97a #edde34;
          border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;

          &:hover {
            background-color: #f3d17a;
            background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
            background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
            background-repeat: repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
            border-color: #f3e97a #f3e97a #edde34;
            border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
          }
        }

        &:hover,
        &.disabled:hover,
        &:active,
        &:hover:active {
          background-color: #f3e97a;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: #f3e97a;
          }
        }

        &.active,
        &:hover.active {
          background-color: #f3e97a;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: #f3e97a;
          }

          background-color: #f3e97a;
        }

        &:hover.disabled {
          background-color: #f3e97a;
        }

        &.disabled {
          &.disabled,
          &:hover.disabled {
            background-color: #f3e97a;
          }
        }

        &[disabled],
        &:hover[disabled] {
          background-color: #f3e97a;
        }

        &.disabled {
          &[disabled],
          &:hover[disabled] {
            background-color: #f3e97a;
          }
        }

        &:active,
        &:hover:active {
          background-color: #efe24b \9;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: #efe24b \9;
          }
        }

        &.active,
        &:hover.active {
          background-color: #efe24b \9;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: #efe24b \9;
          }
        }
      }
    }

    &.selected {
      background-color: #9e9e9e;
      background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
      background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
      background-image: linear-gradient(to bottom, #b3b3b3, #808080);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
      border-color: #808080 #808080 #595959;
      border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: $white;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

      &:hover {
        background-color: #9e9e9e;
        background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: #808080 #808080 #595959;
        border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $white;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }

      &.disabled {
        background-color: #9e9e9e;
        background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
        background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
        background-image: linear-gradient(to bottom, #b3b3b3, #808080);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
        border-color: #808080 #808080 #595959;
        border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $white;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: #9e9e9e;
          background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
          background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
          background-image: linear-gradient(to bottom, #b3b3b3, #808080);
          background-repeat: repeat-x;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
          border-color: #808080 #808080 #595959;
          border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: $white;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
      }

      &:hover,
      &.disabled:hover,
      &:active,
      &:hover:active {
        background-color: #808080;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #808080;
        }
      }

      &.active,
      &:hover.active {
        background-color: #808080;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #808080;
        }

        background-color: #808080;
      }

      &:hover.disabled {
        background-color: #808080;
      }

      &.disabled {
        &.disabled,
        &:hover.disabled {
          background-color: #808080;
        }
      }

      &[disabled],
      &:hover[disabled] {
        background-color: #808080;
      }

      &.disabled {
        &[disabled],
        &:hover[disabled] {
          background-color: #808080;
        }
      }

      &:active,
      &:hover:active {
        background-color: #666666 \9;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: #666666 \9;
        }
      }

      &.active,
      &:hover.active {
        background-color: #666666 \9;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: #666666 \9;
        }
      }
    }

    &.active {
      background-color: $primary;
      background-repeat: repeat-x;
      border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
      filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
      color: $white;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

      &:hover {
        background-color: $primary;
        background-repeat: repeat-x;
        border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $white;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }

      &.disabled {
        background-color: $primary;
        background-repeat: repeat-x;
        border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $white;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: $primary;
          background-repeat: repeat-x;
          border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: $white;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }
      }

      &:hover,
      &.disabled:hover,
      &:active,
      &:hover:active {
        background-color: $border;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: $border;
        }
      }

      &.active,
      &:hover.active {
        background-color: $border;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: $border;
        }

        background-color: $border;
      }

      &:hover.disabled {
        background-color: $border;
      }

      &.disabled {
        &.disabled,
        &:hover.disabled {
          background-color: $border;
        }
      }

      &[disabled],
      &:hover[disabled] {
        background-color: $border;
      }

      &.disabled {
        &[disabled],
        &:hover[disabled] {
          background-color: $border;
        }
      }

      &:active,
      &:hover:active {
        background-color: $primary;
      }

      &.disabled {
        &:active,
        &:hover:active {
          background-color: $primary;
        }
      }

      &.active,
      &:hover.active {
        background-color: $primary;
      }

      &.disabled {
        &.active,
        &:hover.active {
          background-color: $primary;
        }
      }
    }

    span {
      display: block;
      width: 24%;
      height: 54px;
      line-height: 54px;
      float: $float-left;
      margin: 0.5px;
      cursor: pointer;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      background: $white;

      &:hover,
      &.focused {
        color: $white;
        background-color: $primary;
      }

      &.disabled {
        background: none;
        cursor: default;

        &:hover {
          background: none;
          cursor: default;
        }
      }

      &.active {
        background-color: $primary;
        background-repeat: repeat-x;
        border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
        filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
        color: $white;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

        &:hover {
          background-color: $primary;
          background-repeat: repeat-x;
          border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: $white;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        &.disabled {
          background-color: $primary;
          background-repeat: repeat-x;
          border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
          filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
          color: $white;
          text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);

          &:hover {
            background-color: $primary;
            background-repeat: repeat-x;
            border-color: $black-1 $black-1 rgba(0, 0, 0, 0.25);
            filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
            color: $white;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
          }
        }

        &:hover,
        &.disabled:hover,
        &:active,
        &:hover:active {
          background-color: $border;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: $border;
          }
        }

        &.active,
        &:hover.active {
          background-color: $border;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: $border;
          }

          background-color: $border;
        }

        &:hover.disabled {
          background-color: $border;
        }

        &.disabled {
          &.disabled,
          &:hover.disabled {
            background-color: $border;
          }
        }

        &[disabled],
        &:hover[disabled] {
          background-color: $border;
        }

        &.disabled {
          &[disabled],
          &:hover[disabled] {
            background-color: $border;
          }
        }

        &:active,
        &:hover:active {
          background-color: $primary;
        }

        &.disabled {
          &:active,
          &:hover:active {
            background-color: $primary;
          }
        }

        &.active,
        &:hover.active {
          background-color: $primary;
        }

        &.disabled {
          &.active,
          &:hover.active {
            background-color: $primary;
          }
        }
      }
    }
  }

  .datepicker-switch {
    width: 145px;
    cursor: pointer;
  }

  .prev,
  .next,
  tfoot tr th {
    cursor: pointer;
  }

  .datepicker-switch:hover,
  .prev:hover,
  .next:hover,
  tfoot tr th:hover {
    background: $white-1;
    color: $primary-hover;
  }

  .prev.disabled,
  .next.disabled {
    visibility: hidden;
  }

  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-block-start: 3px;
}

.input-daterange {
  input {
    text-align: center;

    &:first-child {
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      -webkit-border-radius: 0 3px 3px 0;
      -moz-border-radius: 0 3px 3px 0;
      border-radius: 0 3px 3px 0;
    }
  }

  .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 $white;
    vertical-align: middle;
    background-color: #f0f2f7;
    border: 1px solid #ccc;
    margin-inline-start: -5px;
    margin-inline-end: -5px;
  }
}
