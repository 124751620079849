.tree {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-inline-start: 1em;
        position: relative;

        ul {
            margin-inline-start: 0.5em;
        }

        &:before {
            content: "";
            display: block;
            width: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            inset-inline-start: -10px;
            border-inline-start: 1px solid $border;
        }
    }

    li {
        margin: 0;
        line-height: 2em;
        color: #56546d;
        position: relative;
    }

    li i {
        display: inline-block;
        margin-inline-end: 5px;
        font-weight: bold;
        color: $primary;
        cursor: pointer;
    }

    ul li {
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 0;
            border-block-start: 1px solid $border;
            margin-block-start: -1px;
            position: absolute;
            top: 1em;
            inset-inline-start: -10px;
        }

        &:last-child:before {
            background: $white;
            height: auto;
            top: 1em;
            bottom: 0;
        }
    }
}

.indicator {
    margin-inline-end: 5px;
    color: #4b10a4;
}

.tree li {
    a {
        text-decoration: none;
        color: #29273c;
    }

    button {
        text-decoration: none;
        color: #29273c;
        border: none;
        background: transparent;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        outline: 0;

        &:active,
        &:focus {
            text-decoration: none;
            color: #29273c;
            border: none;
            background: transparent;
            margin: 0px 0px 0px 0px;
            padding: 0px 0px 0px 0px;
            outline: 0;
        }
    }
}
