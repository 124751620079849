/* PrismJS 1.27.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript */

.token {
    color: #d20808;

    &.cdata,
    &.comment,
    &.doctype,
    &.prolog {
        color: #4cb350;
    }

    &.punctuation {
        color: #b1abab;
    }

    &.namespace {
        opacity: 0.7;
    }

    &.boolean,
    &.constant,
    &.deleted,
    &.number,
    &.property,
    &.symbol,
    &.tag {
        color: #905;
    }

    &.attr-name,
    &.builtin,
    &.char,
    &.inserted,
    &.selector,
    &.string {
        color: #ffc107;
    }
}

.language-css .token.string,
.style .token.string {
    color: #9a6e3a;
    background: hsla(0, 0%, 100%, 0.1);
}

.token {
    &.entity,
    &.operator,
    &.url {
        color: #9a6e3a;
        background: hsla(0, 0%, 100%, 0.1);
    }

    &.atrule,
    &.attr-value,
    &.keyword {
        color: #de20af;
    }

    &.class-name,
    &.function {
        color: #dd4a68;
    }

    &.important,
    &.regex,
    &.variable {
        color: #e90;
    }

    &.bold,
    &.important {
        font-weight: 700;
    }

    &.italic {
        font-style: italic;
    }

    &.entity {
        cursor: help;
    }

    &.tag {
        background: transparent;
    }
}
